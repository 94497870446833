import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("comida-pedido/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("comida-pedido/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.put("comida-pedido", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("comida-pedido/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    cancelar(parametros) {
        return Vue.axios.post("comida-pedido/cancela", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("comida-pedido", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    descargarReporte(parametros) {
        return Vue.axios(
            {
                method: "GET",
                responseType: "blob",
                url: "comida-pedido/reporte",
                params: parametros
            }
        )
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
    },
}